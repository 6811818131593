import { Composition, query } from "atomic-layout"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import * as React from "react"
import HubspotForm from "react-hubspot-form"
import styled from "styled-components"
import facebook from "../../../../static/images/facebook.svg"
import instagram from "../../../../static/images/instagram.svg"
import linkedin from "../../../../static/images/linkedin.svg"
import youtube from "../../../../static/images/youtube.svg"
import { SROnly } from "../../../theme/global"
import { gap } from "../../../utils/spacing"
import Button, { primaryStyles } from "../../atoms/Button"
import Container from "../../atoms/Container"
import Background from "../../molecules/Background"

type LinkProps = {
  link: {
    url: string,
  }
  label: string,
}

interface FooterProps {
  links?: LinkProps[],
  ctaHeading?: string,
  ctaText?: string,
  logo?: GatsbyImageProps,
}

const StyledLinks = styled.div`
  margin-bottom: calc(var(--section-spacing) / 2);

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    li {
      margin-right: var(--menu-item-spacing);

      &:last-child {
        maring-right: 0;
      }

      ${Button} {
        color: #fff;
        font-weight: bold;
        font-size: 1.4375rem;
        text-decoration: none;

        :hover {
          color: #99CDC1;
        }
      }
    }
  }
`

const StyledLogo = styled.div`
  .logo {
    width: 100%;
    height: auto;
  }

  ul.social {
    display: flex;
    padding: 0;
    list-style: none;
    margin-top: 73px;
    justify-content: center;
    @media ${query({ from: "lg" })} {
      justify-content: flex-start;
    }

    li {
      margin-right: 2rem;
      transition: 0.2s;

      &:last-child {
        margin-right: 0;
      }
    }

    img {
      filter: invert(1);
      width: 2rem;

      &:hover {
        filter: invert(81%) sepia(40%) saturate(196%) hue-rotate(116deg) brightness(90%) contrast(88%);
      }
    }
  }
`

const StyledCopyright = styled.div`
  color: #4DA893;
  text-align: center;
  margin-top: 60px;

  @media ${query({ from: "md" })} {
    margin-top: 120px;
  }

  ${Button} {
    color: #4DA893;
    text-decoration: none;

    &:hover {
      color: #99CDC1;
    }
  }
`

const StyledCta = styled.div`
  color: #fff;
  margin-bottom: var(--section-spacing);

  p {
    font-size: 18px;
  }

  @media ${query({ from: "md" })} {
    margin-bottom: 0;
  }

  form {
    display: flex;
    flex-direction: column;

    label:not(.hs-error-msg) {
      ${SROnly}
    }

    .hs-error-msgs {
      margin: 0.5rem 0;
      color: #ff9292;
    }

    .hs_email {
      flex: 1;
      margin-bottom: 1rem;
      margin-right: 20px;
    }

    .hs-button.primary {
      ${primaryStyles}
    }

    @media ${query({ from: "md" })} {
      flex-direction: row;

      input[type='email'] {
        margin-bottom: 0;
      }
    }
  }
`

const areas = `
cta
logo
copyright
`

const areasLg = `
links     links     links
logo      gap       cta
copyright copyright copyright
`

export default function Footer(props: FooterProps) {
  const { links = [], ctaHeading = "", ctaText = "", logo = null } = props

  return (
    <Container
      innerProps={{ paddingVertical: "calc(var(--section-spacing) / 2)" }}
      background={<Background color={"#003428"} />}
    >
      <Composition
        areas={areas}
        templateCols={"1fr"}
        templateRows={"auto"}

        areasMd={areasLg}
        templateRowsMd={"auto"}
        templateColsMd={"4fr 2fr 6fr"}
        gap={gap}
      >
        {(Areas) => (
          <>
            <Areas.Links as={StyledLinks}>
              <ul>
                {links.map(({ link, label }, index) => <li key={"footerLink" + index}>
                  <Button to={link.url} isLink={true}>{label}</Button></li>)}
              </ul>
            </Areas.Links>

            <Areas.Logo as={StyledLogo}>
              {logo?.image && <GatsbyImage {...logo} />}

              <ul className={"social"}>
                <li><a href={"https://www.facebook.com/weareroadmap"}
                       target={"_blank"}><img width="32" height="32" src={facebook} alt={"Facebook"} /></a>
                </li>
                <li><a href={"https://www.instagram.com/weareroadmap/"}
                       target={"_blank"}><img width="32" height="32" src={instagram} alt={"Instagram"} /></a>
                </li>
                <li><a href={"https://www.youtube.com/channel/UCdj9S4nDL7uIo4icBILCtaA"}
                       target={"_blank"}><img width="32" height="32" src={youtube} alt={"Youtube"} /></a>
                </li>
                <li><a href={"https://www.linkedin.com/company/weareroadmap"}
                       target={"_blank"}><img width="32" height="32" src={linkedin} alt={"LinkedIn"} /></a>
                </li>
              </ul>
            </Areas.Logo>

            <Areas.Gap></Areas.Gap>

            <Areas.Cta as={StyledCta}>
              <h3>{ctaHeading}</h3>
              <p>{ctaText}</p>
              <HubspotForm
                portalId={"5265201"}
                formId={"49e59248-a423-4f64-94ee-91bd56926a12"}
              />
            </Areas.Cta>

            <Areas.Copyright as={StyledCopyright}>
              © Copyright {(new Date()).getFullYear()} | <Button isLink={true} href={"/privacy"}>Privacy Policy</Button>
            </Areas.Copyright>
          </>
        )}
      </Composition>
    </Container>
  )
}
